<template>
  <div :class="position" class="cw-chips">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="{ 'error': error }"
      class="cw-chips__chip"
      v-html="nbsp(item)"
    />
  </div>
</template>

<script>
export default {
  name: 'CwChips',

  props: {
    error: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    left: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    position() {
      return `cw-chips--${this.left ? 'left' : 'right'}`;
    },
  },

  methods: {
    nbsp(item) {
      return item.trim().replace(/ /g, '&nbsp;');
    },
  },
};
</script>

<style lang="scss">
.cw-chips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 22px;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &__chip {
    background-color: #00b67a;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-right: 2px;
    padding: 0 4px;
  }
}
</style>
